import { location } from '../content'

function Location() {
  return (
    <div id="location" className="py-16">
      <div className="container mx-auto px-2 prose prose-lg md:prose-2xl">
        <h2>{location.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: location.content }} />
      </div>
    </div>
  )
}

export default Location
