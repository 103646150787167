import { contact } from '../content'

function Contact() {
  return (
    <div id="contact" className="bg-brown-light py-16">
      <div className="container mx-auto px-2 prose prose-lg md:prose-2xl">
        <h2>{contact.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: contact.content }} />
      </div>
      <div className="text-center pt-12">
        <a
          href={contact.button.target}
          target="_blank"
          className="bg-brown-dark text-white hover:text-brown-normal text-2xl font-bold py-4 px-6 border-b-4 border-white hover:border-brown-normal rounded"
          rel="noreferrer"
        >
          {contact.button.text}
        </a>
      </div>
    </div>
  )
}

export default Contact
