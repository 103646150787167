import { about } from '../content'

function About() {
  return (
    <div id="about" className="bg-brown-light py-16">
      <div className="container mx-auto px-2 prose prose-lg md:prose-2xl">
        <h2 className="text-gray-dark">{about.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: about.content }} />
      </div>
    </div>
  )
}

export default About
