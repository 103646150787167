import { menu } from '../content'

function Header() {
  return (
    <header className="py-4">
      <nav className="container mx-auto px-4 lg:px-0">
        <div className="pt-4 pb-8">
          <h1 className="text-center text-4xl text-brown-dark font-bold">
            Манастир "Свети Архангел Михаил"
          </h1>
        </div>
        <ul className="lg:flex items-center justify-between">
          {menu.map((item) => {
            const target = `#${item.target}`
            return (
              <li>
                <a
                  href={target}
                  className="text-brown-dark underline uppercase text-2xl hover:no-underline"
                >
                  {item.label}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  )
}

export default Header
