import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const pictures = [
  '01.jpg',
  '02.jpg',
  '03.jpg',
  '04.jpg',
  '05.jpg',
  '06.jpg',
  '07.jpg',
  '08.jpg',
  '09.jpg',
]

function Slideshow() {
  return (
    <div className="container mx-auto slide-container my-8">
      <Fade arrows={false} duration={2000}>
        {pictures.map((picture, index) => {
          const src = `/pictures/${picture}`
          return <img key={index} style={{ width: '100%' }} src={src} />
        })}
      </Fade>
    </div>
  )
}

export default Slideshow
