import About from './components/About'
import Contact from './components/Contact'
import Donation from './components/Donations'
import Header from './components/Header'
import Impressum from './components/Impressum'
import Location from './components/Location'
import Slideshow from './components/Slideshow'
import ScrollToTop from 'react-scroll-to-top'

function App() {
  return (
    <div className="font-forum">
      <Header />
      <Slideshow />
      <About />
      <Donation />
      <Location />
      <Contact />
      <Impressum />
      <ScrollToTop smooth width="100%" />
    </div>
  )
}

export default App
