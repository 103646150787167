import { impressum } from '../content'

function Impressum() {
  return (
    <div id="impressum" className="bg-brown-normal py-16">
      <div className="container mx-auto px-2 prose prose-lg md:prose-2xl text-white">
        <h2 className="text-white">{impressum.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: impressum.content }} />
      </div>
    </div>
  )
}

export default Impressum
